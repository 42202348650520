<template>
  <div>
    <Label bold class="mb-4">カテゴリ</Label>
    <Select
      dense
      v-model="store.activeEnqueteCategory"
      :options="[
        initialEnqueteCategory,
        ...store.activeCondition.enqueteVModel.map(category => ({
          id: category.id,
          label: category.title
        }))
      ]"
      @change="
        category => {
          store.updateActiveEnqueteCategory(category);
          $emit('change-category');
        }
      "
    />

    <div class="mt-4 question-content" id="scroll-content">
      <div
        v-if="store.activeEnqueteCategory.id !== 0"
        class="enquete-link-card mt-4"
      >
        <div class="enquete-link-content">
          <template
            v-for="(category, categoryIndex) in store.filterdQuestionCategories"
            :key="`enquete-${categoryIndex}`"
          >
            <template
              v-for="(question, questionIndex) in category.questions"
              :key="`question-${questionIndex}`"
            >
              <a
                href="#"
                v-scroll-to="{
                  el: `#category-${category.questionCategoryId}-question-${questionIndex}`,
                  offset: 0,
                  container: '#scroll-content'
                }"
              >
                <Flex align-flex-start class="mt-1">
                  <Typography bold line-height="1.5">
                    {{ question.questionLabel }}
                  </Typography>
                  <TextLink line-height="1.5" class="ml-2">
                    {{ question.questionText }}
                  </TextLink>
                </Flex>
              </a>
            </template>
          </template>
        </div>
      </div>
      <template
        v-for="(category, categoryIndex) in categoriesData"
        :key="`${category.itemCounter}-${categoryIndex}`"
      >
        <div class="customtarget-title mt-10">
          <Typography size="xl" line-height="1.5">
            {{ `${category.categoryLabel}: ${category.categoryText}` }}
          </Typography>
        </div>
        <div
          v-for="(question, questionIndex) in category.questions"
          :key="`question-${questionIndex}`"
          :id="
            `category-${category.questionCategoryId}-question-${questionIndex}`
          "
        >
          <Typography color="grey" line-height="1.5" size="l" class="mt-6 ml-2">
            {{ `${question.questionLabel}：${question.questionText}` }}
          </Typography>
          <template v-for="topic in question.topics" :key="topic.topicId">
            <div class="customtarget-subtitle mt-4 mb-3">
              <Typography bold line-height="1.5">
                {{ `${topic.topicLabel}：${topic.topicText}` }}
                <Tooltip
                  v-if="topic.answerType === 'MULTIPLE'"
                  content="1人で複数回答している可能性があるアンケートです。このタイプでは、特定の選択肢を”含まない”という設定が可能です"
                >
                  <Icon name="question" color="primary" sides="12" />
                </Tooltip>
              </Typography>
            </div>
            <template
              v-for="(option, optionIndex) in topic.options"
              :key="`option-${optionIndex}`"
            >
              <Flex justify-space-between align-center class="mb-4">
                <Checkbox
                  v-if="
                    store.conditions
                      .find(
                        condition => condition.key === store.activeCondition.key
                      )
                      .enqueteVModel.find(
                        enqueteVModel =>
                          enqueteVModel.id === category.questionCategoryId
                      )
                  "
                  v-model="
                    store.conditions
                      .find(
                        condition => condition.key === store.activeCondition.key
                      )
                      .enqueteVModel.find(
                        enqueteVModel =>
                          enqueteVModel.id === category.questionCategoryId
                      )
                      .questions.find(
                        conditionQuestion =>
                          conditionQuestion.id === question.questionId
                      )
                      .topics.find(
                        conditionTopic => conditionTopic.id === topic.topicCode
                      )
                      .options.find(
                        conditionOption =>
                          conditionOption.id === option.optionCode
                      ).checked
                  "
                  :value="option.id"
                  :label="`${optionIndex + 1}.${option.optionText}`"
                  :class="{
                    not: store.conditions
                      .find(
                        condition => condition.key === store.activeCondition.key
                      )
                      .enqueteVModel.find(
                        enqueteVModel =>
                          enqueteVModel.id === category.questionCategoryId
                      )
                      .questions.find(
                        conditionQuestion =>
                          conditionQuestion.id === question.questionId
                      )
                      .topics.find(
                        conditionTopic => conditionTopic.id === topic.topicCode
                      )
                      .options.find(
                        conditionOption =>
                          conditionOption.id === option.optionCode
                      ).not
                  }"
                  class="enquete-checkbox"
                  buttonHeight="100%"
                  block
                  @change="
                    value => {
                      store.resetSample();
                      store.checkEnqueteValue({
                        answerType: topic.answerType,
                        categoryId: category.questionCategoryId,
                        questionId: question.questionId,
                        topicCode: topic.topicCode,
                        optionCode: option.optionCode,
                        checked: value,
                        not: false
                      });
                    }
                  "
                />
                <template v-if="topic.answerType === 'MULTIPLE'">
                  <Switch
                    v-if="
                      store.conditions
                        .find(
                          condition =>
                            condition.key === store.activeCondition.key
                        )
                        .enqueteVModel.find(
                          enqueteVModel =>
                            enqueteVModel.id === category.questionCategoryId
                        )
                        ?.questions.find(
                          conditionQuestion =>
                            conditionQuestion.id === question.questionId
                        )
                        .topics.find(
                          conditionTopic =>
                            conditionTopic.id === topic.topicCode
                        )
                        .options.find(
                          conditionOption =>
                            conditionOption.id === option.optionCode
                        ).checked
                    "
                    v-model="
                      store.conditions
                        .find(
                          condition =>
                            condition.key === store.activeCondition.key
                        )
                        .enqueteVModel.find(
                          enqueteVModel =>
                            enqueteVModel.id === category.questionCategoryId
                        )
                        .questions.find(
                          conditionQuestion =>
                            conditionQuestion.id === question.questionId
                        )
                        .topics.find(
                          conditionTopic =>
                            conditionTopic.id === topic.topicCode
                        )
                        .options.find(
                          conditionOption =>
                            conditionOption.id === option.optionCode
                        ).not
                    "
                    @update:model-value="store.resetSample"
                    label="NOT"
                    bold-in-active
                  />
                </template>
              </Flex>
            </template>
          </template>
        </div>
      </template>
      <InfiniteLoading
        @infinite="loadStoreData"
        target="#scroll-content"
        :distance="1"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from 'vue';
import { scrollTo } from 'vue-scrollto';
import {
  useCustomTargetStore,
  initialEnqueteCategory
} from '@/store/customTarget/';
import Flex from '@/components/layout/Flex.vue';
import Checkbox from '@/components/ui/Checkbox.vue';
import Icon from '@/components/ui/Icon.vue';
import Label from '@/components/ui/Label.vue';
import Select from '@/components/ui/Select.vue';
import Switch from '@/components/ui/Switch.vue';
import TextLink from '@/components/ui/TextLink.vue';
import Tooltip from '@/components/ui/Tooltip.vue';
import Typography from '@/components/ui/Typography.vue';
import InfiniteLoading from 'v3-infinite-loading';

export default defineComponent({
  name: 'EnqueteForm',
  components: {
    Checkbox,
    Flex,
    Icon,
    Label,
    Select,
    Switch,
    TextLink,
    Tooltip,
    InfiniteLoading,
    Typography
  },
  setup() {
    const store = useCustomTargetStore();

    const scrollToTop = () => {
      scrollTo('#scroll-content', 200, {
        container: '#scroll-content'
      });
    };

    // v3-infinite-loading用の設定
    const categoriesData = ref([]);
    let itemCounter = 0;
    const loadStoreData = async $state => {
      try {
        if (itemCounter >= store.filterdQuestionCategories.length) {
          return;
        } else {
          let data = [];
          for (let i = itemCounter; i <= itemCounter + 1; i++) {
            if (store.filterdQuestionCategories[i] === undefined) {
              break;
            }
            data.push(store.filterdQuestionCategories[i]);
          }
          itemCounter += 2;
          categoriesData.value = categoriesData.value.concat(data);
          $state.loaded();
        }
      } catch (error) {
        console.log(error);
      }
    };
    watch(
      () => store.filterdQuestionCategories,
      () => {
        // カテゴリ変更、キーワード検索等が実行された場合、v3-infinite-loading用の値を初期に戻して
        // 更新されたfilterdQuestionCategoriesで再度設定
        categoriesData.value = [];
        itemCounter = 0;
        if (store.filterdQuestionCategories.length > 0) {
          let data = [];
          for (let i = itemCounter; i <= itemCounter + 1; i++) {
            if (store.filterdQuestionCategories[i] === undefined) {
              break;
            }
            data.push(store.filterdQuestionCategories[i]);
          }
          itemCounter += 2;
          categoriesData.value = categoriesData.value.concat(data);
          scrollToTop();
        }
      }
    );

    return {
      store,
      initialEnqueteCategory,
      categoriesData,
      loadStoreData,
      itemCounter
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/spacing';

.customtarget-title {
  background-color: var(--primary-light-color);
  padding: 6px $spacing-base * 2 $spacing-base * 2; // line-height: 1.5 の調整値
}

.customtarget-subtitle {
  background-color: var(--background-color);
  padding: 6px $spacing-base * 2 $spacing-base * 2; // line-height: 1.5 の調整値
}

.enquete-checkbox {
  .base-label {
    margin-left: $spacing-base * 3;
  }
  &.not {
    text-decoration: line-through;
    color: var(--dark-35-color);

    .base-label .ui-typography {
      color: var(--dark-35-color);
    }
  }
}

.question-content {
  max-height: calc(100vh - 40px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.enquete-link-card {
  border: 3px solid var(--primary-light-color);
  border-radius: 8px;
  padding: $spacing-base * 6;
}

.enquete-link-content {
  margin-top: -$spacing-base;
}
</style>
