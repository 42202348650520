<template>
  <div>
    <Label bold class="mb-4">カテゴリ</Label>
    <Select
      dense
      v-model="store.activePurchaseCategory"
      :options="[
        {
          id: Number(initialPurchaseCategory.key),
          label: initialPurchaseCategory.label
        },
        ...store.activeCondition.purchaseVModel.map(category => ({
          id: Number(category.segmentCode),
          label: category.title
        }))
      ]"
      @change="
        category => {
          store.updateActivePurchaseCategory(category.id, category.title);
          $emit('change-category');
        }
      "
    />
    <div class="purchase-content">
      <Flex flex-direction="column" height="84" class="mt-8 mb-10">
        <Flex>
          <Typography size="s" line-height="1.5">
            {{
              `各商品カテゴリについて、直近１年の総購入金額から購入者層を分類しています`
            }}
          </Typography>
        </Flex>
        <div>
          <Flex height="30" align-center class="mt-4">
            <Typography
              size="s"
              line-height="28px"
              class="usage-label"
              textAlign="center"
            >
              {{ `凡例` }}
            </Typography>
            <Icon name="user-heavy" sides="24" class="ml-5" />
            <Flex flex-direction="column" class="ml-2"
              ><Typography size="s" bold> {{ `ヘビーユーザー` }} </Typography
              ><Typography size="xs" class="mt-1">
                {{ `上位20%` }}
              </Typography>
            </Flex>
            <Icon name="user-middle" sides="24" class="ml-5" />
            <Flex flex-direction="column" class="ml-2"
              ><Typography size="s" bold> {{ `ミドルユーザー` }} </Typography
              ><Typography size="xs" class="mt-1">
                {{ `上位20%〜50%` }}
              </Typography>
            </Flex>
            <Icon name="user-light" sides="24" class="ml-5" />
            <Flex flex-direction="column" class="ml-2"
              ><Typography size="s" bold> {{ `ライトユーザー` }} </Typography
              ><Typography size="xs" class="mt-1">
                {{ `上位50%未満` }}
              </Typography>
            </Flex>
            <Icon name="user-none" sides="24" class="ml-5" />
            <Flex flex-direction="column" class="ml-2"
              ><Typography size="s" bold> {{ `ノンユーザー` }} </Typography
              ><Typography size="xs" class="mt-1">
                {{ `購入なし` }}
              </Typography>
            </Flex>
          </Flex>
        </div>
      </Flex>
      <template
        v-for="category in store.filterdPurchaseCategories"
        :key="category.segmentCode"
      >
        <div class="customtarget-title">
          <Typography size="xl" line-height="1.5">
            {{ `${category.segmentName}` }}
          </Typography>
        </div>
        <div
          v-for="middleClass in category.middleClasses"
          :key="`${middleClass.segmentCode}`"
          :id="
            `large-${category.segmentCode}-middle-${middleClass.segmentCode}`
          "
        >
          <Typography color="grey" line-height="1.5" size="l" class="mt-6 ml-2">
            {{ `${middleClass.segmentName}` }}
          </Typography>
          <template
            v-for="minorClass in middleClass.minorClasses"
            :key="minorClass.segmentCode"
            :id="
              `middle-${middleClass.segmentCode}-minor-${minorClass.segmentCode}`
            "
          >
            <div class="customtarget-subtitle mt-4 mb-3">
              <Typography bold line-height="1.5">
                {{ `${minorClass.segmentName}` }}
              </Typography>
            </div>
            <Flex flex-direction="column">
              <template
                v-for="(consumerKey, index) in Object.values(consumerType)"
                :key="`${minorClass.segmentCode}-${consumerKey}`"
                :id="`${minorClass.segmentCode}-${index}`"
              >
                <Checkbox
                  v-if="
                    store.conditions
                      .find(
                        condition => condition.key === store.activeCondition.key
                      )
                      .purchaseVModel.find(
                        purchaseVModel =>
                          purchaseVModel.segmentCode === category.segmentCode
                      )
                  "
                  v-model="
                    store.conditions
                      .find(
                        condition => condition.key === store.activeCondition.key
                      )
                      .purchaseVModel.find(
                        purchaseVModel =>
                          purchaseVModel.segmentCode === category.segmentCode
                      )
                      .middleClasses.find(
                        middle => middle.segmentCode === middleClass.segmentCode
                      )
                      .minorClasses.find(
                        minour => minour.segmentCode === minorClass.segmentCode
                      )
                      .consumerClasses.find(
                        consumer => consumer.consumerClass === consumerKey
                      ).checked
                  "
                  :value="index"
                  :label="
                    makePurchaseLablel(
                      consumerKey,
                      minorClass.purchaseAmountHigher,
                      minorClass.purchaseAmountMedium
                    )
                  "
                  class="mb-4"
                  block
                  buttonHeight="100%"
                  @change="
                    value => {
                      store.resetSample();
                      if (value) {
                        store.checkPurcaseValue({
                          largeSegmentCode: category.segmentCode,
                          middleSegmentCode: middleClass.segmentCode,
                          minorSegmentCode: minorClass.segmentCode,
                          consumerKey: consumerKey,
                          checked: true
                        });
                      }
                    }
                  "
                >
                  <template v-slot:left>
                    <Icon
                      :name="getPurchaseIconName(consumerKey)"
                      sides="20"
                      class="ml-2"
                    />
                  </template>
                </Checkbox>
              </template>
            </Flex>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import {
  useCustomTargetStore,
  initialPurchaseCategory
} from '@/store/customTarget/';
import { consumerType } from '@/store/customTarget/type';
import {
  makePurchaseLablel,
  getPurchaseIconName
} from '@/store/customTarget/purchaseCondition';
import Flex from '@/components/layout/Flex.vue';
import Checkbox from '@/components/ui/Checkbox.vue';
import Icon from '@/components/ui/Icon.vue';
import Label from '@/components/ui/Label.vue';
import Select from '@/components/ui/Select.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'PurchaseForm',
  components: {
    Checkbox,
    Flex,
    Icon,
    Label,
    Select,
    Typography
  },
  setup() {
    const store = useCustomTargetStore();
    return {
      store,
      initialPurchaseCategory,
      consumerType,
      makePurchaseLablel,
      getPurchaseIconName
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/spacing';

.purchase-content {
  max-height: calc(100vh - 40px);
  overflow-y: scroll;
}

.usage-label {
  height: 28px;
  width: 56px;
  vertical-align: middle;
  background-color: var(--dark-10-hex-color);
}

.customtarget-title {
  background-color: var(--primary-light-color);
  padding: 6px $spacing-base * 2 $spacing-base * 2;
}

.customtarget-subtitle {
  background-color: var(--background-color);
  padding: 6px $spacing-base * 2 $spacing-base * 2;
}
</style>
