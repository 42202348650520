<template>
  <DataList
    :items="
      store.activeCondition.basicCondition.familyGenderAges?.map(
        (genderAge, index) => ({
          familyGender: genderAge.familyGenderAgeFilters.find(
            item => item.field === 'familyGender'
          ),
          familyAge: genderAge.familyGenderAgeFilters.filter(
            item => item.field !== 'familyGender'
          ),
          removeIndex: index
        })
      )
    "
    :headers="[
      {
        id: 'familyGender',
        label: '性別',
        width: '240px'
      },
      {
        id: 'familyAge',
        label: '年齢'
      },
      {
        id: 'removeIndex',
        label: ''
      }
    ]"
    spacing="s"
    class="family-setting"
  >
    <template v-slot:familyGender="{ item, index }">
      <div class="pb-4 pt-4">
        <Radio
          v-model="item.value"
          :name="`gender-${index}`"
          label="指定なし"
          value=""
          @change="
            () => {
              store.resetSample();
            }
          "
        />
        <Radio
          v-model="item.value"
          :name="`gender-${index}`"
          label="女性"
          value="f"
          class="ml-4"
          @change="
            () => {
              store.resetSample();
            }
          "
        />
        <Radio
          v-model="item.value"
          :name="`gender-${index}`"
          label="男性"
          value="m"
          class="ml-4"
          @change="
            () => {
              store.resetSample();
            }
          "
        />
      </div>
    </template>

    <template v-slot:familyAge="{ item, index }">
      <Flex align-center class="pt-4">
        <Flex align-center width="103px">
          <TextField
            v-model="
              item.find(field => field.op === 'GREATER_THAN_OR_EQUAL').value
            "
            size="m"
            placeholder="0"
            :error-message="
              (() => {
                const valid = store.validateFamilyAge(
                  store.activeConditionKey,
                  index
                );
                return valid.from ? '' : VALIDATION_MESSAGE.invalidNumber;
              })()
            "
            @change="
              () => {
                store.resetSample();
              }
            "
          />
          <Typography width="80px" class="ml-2">歳</Typography>
        </Flex>
        <Typography class="mx-2">〜</Typography>
        <Flex align-center width="103px">
          <TextField
            v-model="
              item.find(field => field.op === 'LESS_THAN_OR_EQUAL').value
            "
            size="m"
            placeholder="99"
            :error-message="
              (() => {
                const valid = store.validateFamilyAge(
                  store.activeConditionKey,
                  index
                );
                return valid.to ? '' : VALIDATION_MESSAGE.invalidNumber;
              })()
            "
            @change="
              () => {
                store.resetSample();
              }
            "
          />
          <Typography width="80px" class="ml-2">歳</Typography>
        </Flex>
      </Flex>
    </template>
    <template v-slot:removeIndex="{ index }">
      <template
        v-if="store.activeCondition.basicCondition.familyGenderAges?.length > 1"
      >
        <Flex justify-end>
          <IconButton
            icon-name="close-circle"
            @click="
              () => {
                store.removeFamilySetting(index);
                store.resetSample();
              }
            "
          />
        </Flex>
      </template>
      {{ '' }}
    </template>
  </DataList>
  <Button
    v-if="store.activeCondition.basicCondition.familyGenderAges?.length < 3"
    block
    variant="outlined"
    iconName="plus"
    @click="store.addFamilySetting"
  >
    追加
  </Button>
</template>
<script setup>
import { useCustomTargetStore } from '@/store/customTarget/';
import { VALIDATION_MESSAGE } from '@/common/validation';
import Flex from '@/components/layout/Flex.vue';
import Button from '@/components/ui/Button.vue';
import DataList from '@/components/ui/DataList.vue';
import IconButton from '@/components/ui/IconButton.vue';
import Radio from '@/components/ui/Radio.vue';
import TextField from '@/components/ui/TextField.vue';
import Typography from '@/components/ui/Typography.vue';

const store = useCustomTargetStore();
</script>
<style lang="scss">
.family-setting .error-message {
  position: absolute;
}
</style>
