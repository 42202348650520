<template>
  <LayoutWithSidebar
    :breadcrumbs="breadcrumbs"
    :title="store.customTarget?.name"
    subtitle="カスタムターゲットの編集"
  >
    <template v-slot:button-content>
      <Button
        v-if="store.customTarget"
        size="m"
        icon-name="trash"
        variant="outlined"
        @click="
          openRemoveModal(store.customTarget?.id, store.customTarget?.name)
        "
      >
        削除
      </Button>
    </template>
    <Box
      v-if="isLoadingDataEditing || basicTargetStore.isLoading"
      position="absolute"
      top="calc(50px + 92px)"
      left="230px"
      margin-top="calc((100vh - 50px - 92px - 38px) / 2 - 18px)"
      margin-left="calc((100% - 230px) / 2 - 18px)"
    >
      <Loading size="m" />
    </Box>
    <CustomTargetForm v-else />
  </LayoutWithSidebar>
  <RemoveModal
    title="カスタムターゲットの削除"
    :warningMessage="
      store.customTarget?.inUse ? 'このターゲットは現在使用中です。' : undefined
    "
    :caption="
      store.customTarget?.inUse
        ? '削除すると、利用先の分析結果に影響が出たり、一時的に利用できなくなる場合があります'
        : undefined
    "
    :loading="isRemovingCustomTarget"
    v-model="isRemoveModalOpen"
    :target="removedCustomTargetName"
    @remove="
      async () => {
        await removeCustom();
        await router.push({ name: CUSTOM_ROUTES.index, params: { companyId } });
      }
    "
  />
</template>
<script>
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { COMPANY_ROUTES, CUSTOM_ROUTES } from '@/router';
import {
  useRemoveCustom,
  useCustomTargetEdit
} from '@/composables/customTarget';
import { useCustomTargetStore } from '@/store/customTarget/';
import { useBasicTargetsStore } from '@/store/basicTargets';
import CustomTargetForm from '@/pages/custom/CustomTargetForm.vue';
import Box from '@/components/layout/Box.vue';
import LayoutWithSidebar from '@/components/layout/LayoutWithSidebar.vue';
import Button from '@/components/ui/Button.vue';
import Loading from '@/components/ui/Loading.vue';
import RemoveModal from '@/components/ui/RemoveModal.vue';

export default defineComponent({
  name: 'CustomEdit',
  components: {
    Button,
    Box,
    CustomTargetForm,
    LayoutWithSidebar,
    Loading,
    RemoveModal
  },
  setup() {
    const {
      params: { id, companyId }
    } = useRoute();
    const router = useRouter();
    const store = useCustomTargetStore();
    const basicTargetStore = useBasicTargetsStore();
    const breadcrumbs = {
      parents: [
        { name: COMPANY_ROUTES.top, label: 'ホーム' },
        { name: CUSTOM_ROUTES.index, label: 'カスタムターゲット' }
      ],
      current: { label: 'カスタムターゲットの編集' }
    };

    const {
      isRemoveModalOpen,
      isRemovingCustomTarget,
      removedCustomTargetName,
      openRemoveModal,
      removeCustom
    } = useRemoveCustom();

    const { getDataEditing, isLoadingDataEditing } = useCustomTargetEdit();
    getDataEditing(id);

    return {
      CUSTOM_ROUTES,
      router,
      store,
      basicTargetStore,
      breadcrumbs,
      isRemoveModalOpen,
      isRemovingCustomTarget,
      isLoadingDataEditing,
      removedCustomTargetName,
      openRemoveModal,
      removeCustom,
      companyId
    };
  }
});
</script>
