<template>
  <div>
    <Flex align-center>
      <Label class="mr-4">性／年齢</Label>
      <Select
        dense
        :options="[
          ...basicTargets
            .filter(basicTarget =>
              [
                BASIC_TARGET_DIVISION.ga12,
                BASIC_TARGET_DIVISION.ga10s
              ].includes(basicTarget.divisionName)
            )
            .map(basicTarget => ({
              id: basicTarget?.id,
              label: basicTarget?.displayDivisionName
            })),
          { id: 0, label: '手動設定' }
        ]"
        v-model="basicTarget"
        width="250px"
      />
    </Flex>
    <div class="mt-6">
      <template
        v-if="
          basicTarget?.id ===
            basicTargets.find(
              basicTarget =>
                basicTarget.divisionName === BASIC_TARGET_DIVISION.ga12
            )?.id
        "
      >
        <CheckboxButtonGroup
          v-model="store.activeCondition.basicCondition.basicTargetIds"
          size="xs"
          :items="
            basicTargets
              ?.find(
                basicTarget =>
                  basicTarget.divisionName === BASIC_TARGET_DIVISION.ga12
              )
              ?.targets.map(target => ({
                id: target.id,
                label: target.displayTargetName,
                tooltipContent: target.displayTargetDescription
              }))
          "
          @change="
            () => {
              updateGenderAges();
              store.resetSample();
            }
          "
        />
      </template>
      <template
        v-else-if="
          basicTarget?.id ===
            basicTargets.find(
              basicTarget =>
                basicTarget.divisionName === BASIC_TARGET_DIVISION.ga10s
            )?.id
        "
      >
        <CheckboxButtonGroup
          v-model="store.activeCondition.basicCondition.basicTargetIds"
          size="xs"
          :items="
            basicTargets
              ?.find(
                basicTarget =>
                  basicTarget.divisionName === BASIC_TARGET_DIVISION.ga10s
              )
              ?.targets.map(target => ({
                id: target.id,
                label: target.displayTargetName,
                tooltipContent: target.displayTargetDescription
              }))
          "
          @change="
            () => {
              updateGenderAges();
              store.resetSample();
            }
          "
        />
      </template>
      <template v-else>
        <ManualSetting />
      </template>
    </div>
    <Divider class="my-6" />
    <Flex>
      <Box min-width="200px" class="mr-4">
        <Label bold size="m">職業</Label>
      </Box>
      <Flex full-width flex-wrap>
        <Checkbox
          v-model="store.activeCondition.basicCondition.occupation"
          :value="0"
          label="指定なし"
          class="job-checkbox"
          @change="
            () => {
              store.updateBasicCondition('occupation', [0]);
              store.resetSample();
            }
          "
        />
        <template v-for="occupation in OCCUPATIONS" :key="occupation.id">
          <Checkbox
            v-model="store.activeCondition.basicCondition.occupation"
            :value="occupation.id"
            :label="occupation.label"
            class="job-checkbox"
            @change="
              () => {
                let occupations = store.activeCondition.basicCondition.occupation.filter(
                  value => value !== 0
                );
                if (occupations.length === 0) {
                  occupations = [0];
                }
                store.updateBasicCondition('occupation', occupations);
                store.resetSample();
              }
            "
          />
        </template>
      </Flex>
    </Flex>
    <Flex class="mt-8">
      <Box min-width="200px" class="mr-4">
        <Label bold size="m">未既婚</Label>
      </Box>
      <Flex>
        <Radio
          v-model="store.activeCondition.basicCondition.married"
          :value="undefined"
          label="指定なし"
          class="mr-4"
          @change="store.resetSample"
        />
        <Radio
          v-model="store.activeCondition.basicCondition.married"
          :value="false"
          label="未婚"
          class="mr-4"
          @change="store.resetSample()"
        />
        <Radio
          v-model="store.activeCondition.basicCondition.married"
          :value="true"
          label="既婚"
          class="mr-4"
          @change="store.resetSample()"
        />
      </Flex>
    </Flex>
    <div class="mt-8">
      <Flex class="mb-4">
        <Label bold size="m">同居家族の年齢/性別</Label>
      </Flex>
      <Family />
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { initGenderAges } from '@/store/customTarget/basicCondition';
import { storeToRefs } from 'pinia';
import { useCustomTargetStore } from '@/store/customTarget/';
import { useBasicTargetsStore } from '@/store/basicTargets';
import { BASIC_TARGET_DIVISION, OCCUPATIONS } from '@/common/constant';
import ManualSetting from '@/pages/custom/ManualSetting.vue';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Label from '@/components/ui/Label.vue';
import Checkbox from '@/components/ui/Checkbox.vue';
import CheckboxButtonGroup from '@/components/ui/CheckboxButtonGroup.vue';
import Divider from '@/components/ui/Divider.vue';
import Radio from '@/components/ui/Radio.vue';
import Select from '@/components/ui/Select.vue';
import Family from '@/pages/custom/Family.vue';

const basicTargetStore = useBasicTargetsStore();
const store = useCustomTargetStore();
const { activeConditionKey, activeCondition } = storeToRefs(store);
const { basicTargets } = storeToRefs(basicTargetStore);
const manualSetting = { id: 0, label: '手動設定' };
const ga12 = basicTargets.value.find(
  target => target.divisionName === BASIC_TARGET_DIVISION.ga12
);
const basicTarget = ref(
  ga12
    ? {
        id: ga12.id,
        label: ga12.displayDivisionName
      }
    : manualSetting
);

const updateGenderAges = () => {
  // 選択したの性／年齢の区分(「性・年齢12区分」または「男女10歳区分」または「手動設定」)内の値のみで更新
  const selectBasicTargets = basicTargets.value.find(
    target => target.displayDivisionName === basicTarget.value.label
  ).targets;
  const selectIds = selectBasicTargets
    .filter(selectBasicTarget =>
      activeCondition.value.basicCondition.basicTargetIds.includes(
        selectBasicTarget.id
      )
    )
    .map(t => t.id);
  store.updateBasicCondition('basicTargetIds', selectIds);
  store.updateBasicCondition('genderAges', [initGenderAges()]);
};

watch(
  activeConditionKey,
  () => {
    if (activeCondition.value.basicCondition.basicTargetIds.length > 0) {
      // activeConditionが「性・年齢12区分」もしくは「男女10歳区分」の値を持っている
      const _basicTarget = basicTargets.value.find(basic =>
        basic.targets.some(target =>
          activeCondition.value.basicCondition.basicTargetIds.includes(
            target.id
          )
        )
      );
      basicTarget.value = {
        id: _basicTarget?.id || 0,
        label: _basicTarget?.displayDivisionName || ''
      };
    } else if (
      // activeConditionが「手動設定」の値を持っている
      activeCondition.value.basicCondition.genderAges.some(genderAge =>
        genderAge.genderAgeFilters.some(value => !!value.value)
      )
    ) {
      basicTarget.value = manualSetting;
    }
  },
  {
    immediate: true
  }
);
</script>

<style lang="scss" scoped>
@import '@/styles/spacing';

.job-checkbox {
  width: 260px;
  margin-bottom: $spacing-base * 2;
}
</style>
