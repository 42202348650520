
import { defineComponent, PropType } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Typography from '@/components/ui/Typography.vue';
import Tag from '@/components/ui/Tag.vue';
import { TagValue } from '@/store/customTarget/type';

export default defineComponent({
  name: 'ConditionTags',
  components: {
    Flex,
    Typography,
    Tag
  },
  emits: ['closeTag'],
  props: {
    label: String,
    tags: {
      type: Array as PropType<TagValue[]>
    },
    canClose: Boolean
  }
});
