<template>
  <div v-if="isAlertOpen" class="custom-target-alert">
    <Alert :is-open="isAlertOpen" @close="closeAlert">
      データが更新されています。条件とサンプルサイズを確認してください
    </Alert>
  </div>
  <Flex justify-space-between align-flex-start>
    <Box
      width="calc(100% - 320px)"
      class="custom-target-form"
      :class="{ 'is-alert-open': isAlertOpen }"
    >
      <Card>
        <SearchTextField
          v-model="searchKeyword"
          placeholder="条件を探す（キーワードを入力）"
          :clear="searchKeyword.length > 0"
          :loading="isLoadingTargetCategories"
          @click="
            keyword => {
              if (!keyword) return;
              search(keyword);
            }
          "
          @clear="search"
          @input="onInputSearch"
        />
        <Tabs :tab-list="tabList" @change="onChangeTab" class="mt-4" />
        <Box class="mt-6">
          <BasicForm v-show="activeTabId === tabIds.basic" />
          <EnqueteForm
            v-show="activeTabId === tabIds.enquete"
            @change-category="
              () => {
                searchKeyword = '';
                getTargetCategories({ currentCompanyId });
                resetCount();
              }
            "
          />
          <PurchaseForm
            v-show="activeTabId === tabIds.purchase"
            @change-category="
              () => {
                searchKeyword = '';
                getTargetCategories({ currentCompanyId });
                resetCount();
              }
            "
          />
        </Box>
      </Card>
      <CheckSample class="mt-6" />
    </Box>
    <Box
      width="320px"
      position="sticky"
      :top="isAlertOpen ? '245px' : '160px'"
      class="custom-target-conditions ml-6"
      :class="{ 'is-alert-open': isAlertOpen }"
    >
      <Conditions />
    </Box>
  </Flex>
  <Modal v-model="isCardError.isShow">
    <div class="mb-4">
      <Typography text-align="center"> {{ isCardError.message }}</Typography>
    </div>
    <Flex justify-center>
      <Button
        class="mt-2"
        width="120px"
        size="m"
        @click="isCardError.isShow = false"
        >OK</Button
      >
    </Flex>
  </Modal>
  <Modal
    v-model="store.errorModal.isShow"
    :title="store.errorModal.title"
    @close="
      () => {
        store.errorModal.isShow = false;
      }
    "
  >
    <Typography>
      <Typography>
        {{ store.errorModal.message }}
      </Typography>
    </Typography>
    <Flex class="mt-6" justify-center>
      <Button
        class="ml-2"
        width="140px"
        size="m"
        @click="
          () => {
            store.errorModal.isShow = false;
          }
        "
      >
        OK
      </Button>
    </Flex>
  </Modal>
</template>
<script setup>
import { ref, watch } from 'vue';
import { WorkspaceFeatureOptionTypeEnum } from '@/api/openapi';
import { useCustomTargetStore } from '@/store/customTarget/';
import { storeToRefs } from 'pinia';
import { useTabs } from '@/composables/tabs';
import useAlert from '@/composables/alert';
import { useCategories } from '@/composables/customTarget';
import { useRoute } from 'vue-router';
import { useUserInfoStore } from '@/store/userInfo';
import CheckSample from '@/pages/custom/CheckSample.vue';
import Conditions from '@/pages/custom/Conditions.vue';
import BasicForm from '@/pages/custom/BasicForm.vue';
import EnqueteForm from '@/pages/custom/EnqueteForm.vue';
import PurchaseForm from '@/pages/custom/PurchaseForm.vue';

import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';

import Alert from '@/components/ui/Alert.vue';
import Button from '@/components/ui/Button.vue';
import Card from '@/components/ui/Card.vue';
import Modal from '@/components/ui/Modal.vue';
import SearchTextField from '@/components/ui/SearchTextField.vue';
import Tabs from '@/components/ui/Tabs.vue';
import Typography from '@/components/ui/Typography.vue';

const route = useRoute();
const userInfoStore = useUserInfoStore();
const store = useCustomTargetStore();
const { customTarget } = storeToRefs(store);
const tabIds = {
  basic: 'basic',
  enquete: 'enquete',
  purchase: 'purchase',
  media: 'media'
};
const { activeTabId, changeTab } = useTabs(tabIds.basic);
const searchKeyword = ref('');
const { isAlertOpen, closeAlert, openAlert } = useAlert(false);
const { getTargetCategories, isLoadingTargetCategories } = useCategories();

const purchaseStatus = userInfoStore.getFeatureOptionStatus(
  route,
  WorkspaceFeatureOptionTypeEnum.PurchaseLog
);

const currentCompanyId = route.params.companyId.toString();

const tabList = ref([
  {
    id: tabIds.basic,
    name: '基本属性'
  },
  {
    id: tabIds.enquete,
    name: 'アンケート'
  },
  {
    id: tabIds.purchase,
    name: '購買ログ',
    lock: !purchaseStatus,
    showLockIcon: !purchaseStatus
  },
  {
    id: tabIds.media,
    name: 'メディア接触',
    lock: true,
    showLockIcon: true
  }
]);

const onChangeTab = tabId => {
  changeTab(tabId);
};

const getCount = id => {
  switch (id) {
    case tabIds.enquete:
      return store.enqueteTopicsCount;
    case tabIds.purchase:
      return store.purchaseMinorClassesCount;
    default:
      return undefined;
  }
};
const search = async keyword => {
  await getTargetCategories({
    currentCompanyId,
    keyword,
    type: 'search'
  });
  store.resetEnqueteCategory();
  store.resetPurchaseCategory();
  if (keyword) {
    tabList.value = tabList.value.map(tab => ({
      ...tab,
      count: getCount(tab.id)
    }));
  } else {
    resetCount();
  }
};
const resetCount = () => {
  tabList.value = tabList.value.map(tab => ({
    ...tab,
    count: undefined
  }));
};

watch(
  customTarget,
  () => {
    if (!customTarget.value) return;
    if (customTarget.value.isDataSourceUpdated) {
      openAlert();
    }
  },
  {
    immediate: true
  }
);

const onInputSearch = ({ target }) => {
  if (target.value === '') {
    resetCount();
    getTargetCategories({ currentCompanyId });
  }
};

const isCardError = ref({ isShow: true, message: '' });
const init = () => {
  const hasSingleAnswerCard = store.isAndDisabledList.some(
    list => list.Op === 'AND' && list.isAndDisabled === true
  );
  isCardError.value = {
    isShow: store.isInvalidCard || hasSingleAnswerCard,
    message: hasSingleAnswerCard
      ? '利用できない条件または不正な条件が含まれているため削除してください'
      : '利用できない条件が含まれているため削除してください'
  };

  store.resetSample();
};

init();
</script>
<style lang="scss" scoped>
@import '@/styles/spacing';
@import '@/styles/layout';

$alert-height: 103px;
.custom-target-alert {
  position: sticky;
  top: $header-navigation-height + $content-header-height;
  padding: $spacing-base * 6 0;
  margin-top: -$spacing-base * 6;
  background-color: var(--background-color);
  z-index: 3;
}

.custom-target-form {
  &.is-alert-open {
    overflow-y: scroll;
  }
}

.custom-target-conditions {
  height: calc(
    100vh -
      (
        $header-navigation-height + $content-header-height + $spacing-base * 6 +
          $spacing-base * 6
      )
  );

  &.is-alert-open {
    height: calc(
      100vh -
        (
          $header-navigation-height + $content-header-height + $alert-height +
            $spacing-base * 6
        )
    );
  }
}
</style>
