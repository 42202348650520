import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Flex = _resolveComponent("Flex")!

  return (_ctx.tags && _ctx.tags.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Typography, {
          bold: "",
          "line-height": "1.5",
          class: "mb-1 custom-tags-label"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Flex, {
          "flex-wrap": "",
          class: "condition-tag"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  tag
                    ? (_openBlock(), _createBlock(_component_Tag, {
                        key: 0,
                        label: tag.label,
                        "can-close": _ctx.canClose,
                        variant: tag.not ? 'outlined' : undefined,
                        error: tag.errorMessage ? 'error' : undefined,
                        errorMessage: tag.errorMessage,
                        onClose: ($event: any) => (_ctx.$emit('closeTag', { index, tag }))
                      }, null, 8, ["label", "can-close", "variant", "error", "errorMessage", "onClose"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ])
          ]),
          _: 3
        })
      ]))
    : _createCommentVNode("", true)
}