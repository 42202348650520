<template>
  <Card title="カスタムターゲットの作成" class="mt-6">
    <ValidationForm
      :validation-schema="schema"
      v-slot="{ meta: { valid } }"
      @submit="handleSubmit"
    >
      <Label required bold size="m">ターゲット名</Label>
      <VeeTextField
        name="name"
        :value="store.customTarget?.name"
        placeholder="全角２４文字まで"
        class="mt-4"
      />
      <Label bold size="m" class="mt-6">説明文</Label>
      <VeeTextField
        name="description"
        :value="store.customTarget?.description"
        placeholder="全角３６文字まで"
        class="mt-4"
      />
      <Label required bold size="m" class="mt-9">サンプルサイズの確認</Label>
      <Typography size="s" color="grey" class="mt-3">
        サンプルサイズが大きいほど信頼性の高い分析結果に近づきます
      </Typography>
      <Button
        block
        type="button"
        variant="outlined"
        class="mt-4"
        :loading="isLoadingSample"
        @click="
          checkSample({
            cardCondition: store.cardConditionForm,
            conditionCards: store.conditionCardsForm
          })
        "
      >
        チェック
      </Button>
      <div v-if="store.isDisplaySample" class="mt-4">
        <template v-if="!store.checkedSample">
          <Flex align-center justify-center class="sample-count">
            <Typography size="xl" bold>0</Typography>
          </Flex>
          <Typography text-align="center" color="error" class="mt-4">
            サンプルがありません。条件を見直してください。
          </Typography>
        </template>
        <template v-else>
          <Flex
            flex-direction="column"
            align-center
            justify-center
            class="sample-count"
            :class="{
              missing: store.isSmallSample,
              valid: !store.isSmallSample
            }"
          >
            <Flex align-center justify-center class="mb-4">
              <Flex
                v-for="sample in store.initialSamples"
                :key="sample.areaName"
                align-baseline
                class="ml-4"
              >
                <Typography size="s">{{ sample.areaName }}:</Typography>
                <Typography size="xxl" bold>{{
                  sample.number.toLocaleString()
                }}</Typography>
              </Flex>
            </Flex>
            <Flex
              v-if="isCollapsed"
              flex-direction="column"
              align-center
              class="collapse-samples"
            >
              <Flex align-center class="clickable" @click="collapse"
                ><Icon name="chevron-down" color="primary"></Icon>
                <Typography size="s" color="primary">全エリアを表示</Typography>
              </Flex>
            </Flex>
            <Flex
              v-else
              flex-direction="column"
              align-center
              class="collapse-samples"
            >
              <Flex align-center class="clickable mb-4" @click="expand"
                ><Icon name="chevron-up" color="primary"></Icon>
                <Typography size="s" color="primary">閉じる</Typography>
              </Flex>
              <Flex class="contracted-area">
                <Flex
                  v-for="sample in store.contractedSamples"
                  :key="sample.areaName"
                  align-baseline
                  class="contracted-area-item"
                >
                  <Typography size="s">{{ sample.areaName }}</Typography>
                  <Typography size="s" color="grey">：</Typography>
                  <Typography size="s">{{
                    sample.number.toLocaleString()
                  }}</Typography>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Typography
            v-if="store.isSmallSample"
            text-align="center"
            color="link"
            class="mt-4"
          >
            サンプルサイズが100以上になる条件を目指してみましょう
          </Typography>
        </template>
      </div>
      <Button
        block
        :disabled="!valid || !store.checkedSample"
        :loading="isPostingCustomTarget || isPuttingCustomTarget"
        size="l"
        class="mt-4"
      >
        {{ isEditing ? '保存' : '作成' }}
      </Button>
    </ValidationForm>
  </Card>
  <Modal v-model="isOpenWarningModal" title="使用中のカスタムターゲットです">
    <div :style="{ width: '400px' }">
      <Typography line-height="1.5" class="mb-6">
        使用中のカスタムターゲットを更新します。
        更新が完了するまで数分かかる場合があります。よろしいですか？
      </Typography>
      <Flex class="mt-6" justify-center>
        <Button
          class="mr-2"
          width="120px"
          size="m"
          variant="outlined"
          @click="isOpenWarningModal = false"
        >
          キャンセル
        </Button>
        <Button
          class="ml-2"
          width="120px"
          size="m"
          @click="
            submitCustomTarget();
            isOpenWarningModal = false;
          "
        >
          OK
        </Button>
      </Flex>
    </div>
  </Modal>
</template>
<script>
import { defineComponent, computed, ref } from 'vue';
import * as Yup from 'yup';
import { useRoute } from 'vue-router';
import { useCustomTargetStore } from '@/store/customTarget/';
import { useCheckSample, useCustomTarget } from '@/composables/customTarget';
import { VALIDATION_MESSAGE } from '@/common/validation';
import { Form as ValidationForm } from 'vee-validate';
import Flex from '@/components/layout/Flex.vue';
import Modal from '@/components/ui/Modal.vue';
import Button from '@/components/ui/Button.vue';
import Card from '@/components/ui/Card.vue';
import Label from '@/components/ui/Label.vue';
import Typography from '@/components/ui/Typography.vue';
import VeeTextField from '@/components/veeValidate/VeeTextField.vue';
import Icon from '@/components/ui/Icon';
import useToggle from '@/composables/toggle';

export default defineComponent({
  name: 'CheckSample',
  components: {
    Button,
    Card,
    Modal,
    Flex,
    Label,
    Typography,
    ValidationForm,
    Icon,
    VeeTextField
  },
  setup() {
    const store = useCustomTargetStore();
    const {
      params: { id, companyId }
    } = useRoute();
    const isEditing = computed(() => !!id);
    const [isCollapsed, expand, collapse] = useToggle(true);

    const { isLoadingSample, checkSample } = useCheckSample(companyId);
    const isOpenWarningModal = ref(false);

    const name = ref(store.customTarget?.name);
    const description = ref(store.customTarget?.description);

    const handleSubmit = async values => {
      name.value = values.name;
      description.value = values.description;
      if (store.customTarget?.inUseWithReachSimulator) {
        isOpenWarningModal.value = true;
      } else {
        submitCustomTarget();
      }
    };

    const {
      isPostingCustomTarget,
      postCustomTarget,
      isPuttingCustomTarget,
      putCustomTarget
    } = useCustomTarget();

    const submitCustomTarget = () => {
      isEditing.value
        ? putCustomTarget({
            customTargetId: id,
            customTargetForm: {
              name: name.value,
              description: description.value,
              cardCondition: store.cardConditionForm,
              conditionCards: store.conditionCardsForm
            }
          })
        : postCustomTarget({
            name: name.value,
            description: description.value,
            cardCondition: store.cardConditionForm,
            conditionCards: store.conditionCardsForm
          });
    };

    return {
      store,
      schema: Yup.object().shape({
        name: Yup.string()
          .max(24, `全角${VALIDATION_MESSAGE.maxN(24)}`)
          .required(VALIDATION_MESSAGE.required),
        description: Yup.string().max(36, `全角${VALIDATION_MESSAGE.maxN(36)}`)
      }),
      checkSample,
      submitCustomTarget,
      isLoadingSample,
      isPostingCustomTarget,
      isOpenWarningModal,
      handleSubmit,
      postCustomTarget,
      isEditing,
      isPuttingCustomTarget,
      isCollapsed,
      expand,
      collapse
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/spacing';

.sample-count {
  &.valid {
    background-color: var(--primary-light-color);
  }

  &.missing {
    color: var(--link-text-color);
  }

  &.error {
    color: var(--error-color);
  }
  padding: $spacing-base * 4 0;
  align-content: space-around;
  align-items: stretch;
}
.collapse-samples {
  background-color: var(--contrast-color);
  padding: $spacing-base * 3 0;
  margin: 0 $spacing-base * 2;
  .clickable {
    cursor: pointer;
  }
  .contracted-area-item {
    padding: 0 $spacing-base * 3;
  }
  .contracted-area {
    flex-wrap: wrap;
  }
  .contracted-area-item:has(+ .contracted-area-item) {
    border-right: 1px solid var(--grey-text-color);
  }
}
</style>
